import styled, { css } from 'styled-components'

export const ItemList = styled.div`
  padding-top: 10px;
  display: grid;
  grid-template-columns: ${props => (props.cart ? '100%' : '48% 48%')};
  grid-template-rows: min-content;
  grid-row-gap: 12px;
  grid-column-gap: 12px;
  overflow: auto;
  align-content: start;
  overflow-y: auto;
  overflow-x: hidden;
`

export const FakeWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1 1 auto;

  ::-webkit-scrollbar {
    width: 2px;
    margin-right: -4px;
  }
  ::-webkit-scrollbar-button {
    background: #f1f1f1;
    height: 2px;
  }
  ::-webkit-scrollbar-track-piece {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: ${props => (props.theme.main)};
  }
`

export const ShowCartButtonContainer = styled.div`
  background: ${props => props.theme.white};
  bottom: 0;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.5);
  display: block;
  left: 0;
  padding: 12px 16px;
  position: fixed;
  right: 0;
`

export const EmptyCartContainer = styled.div`
  display: block;
  margin: 20px 0;
  position: relative;
  text-align: center;
  width: 100%;

  > button {
    background: none;
    border: none;
    color: ${props => props.theme.darkGray};
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    max-width: 220px;
    opacity: 0.6;
    position: relative;
    text-align: center;
    text-decoration: underline;
    width: 100%;
    outline: none;
  }
`

export const SkeletonItem = styled.div`
  background: ${props => props.theme.white};
  box-shadow: 0px 20px 40px rgba(239, 239, 239, 0.8);
  border-radius: 6px;
  display: flex;
  flex-direction: ${props => (props.cart ? 'row' : 'column')};
  position: relative;
  padding: 9px;
  height: ${props => (props.cart ? '48px' : '182px')};
  max-height: ${props => (props.cart ? '48px' : '182px')};
  justify-content: space-around;
  border: 2px solid ${props => (props.qty > 0 ? props.theme.main : props.theme.white)};

  ${props => (props.cart
    ? css`
      border-radius: 4px 24px 24px 4px;
      box-shadow: 0px 20px 40px rgba(239, 239, 239, 0.8);
      justify-content: space-between;
      width: 100%;
      max-width: 100%;
      padding: 0;
      border: 0;
    ` : null)
}
`
