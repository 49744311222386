import { createTypes, actionCreator, async } from 'redux-action-creator'

export const types = createTypes([
  ...async('LOAD'),
  ...async('SEARCH'),
  ...async('LOAD_USER_INFO'),
  ...async('SEND_CART'),
  'ADD_TO_CART',
  'REMOVE_FROM_CART',
  'EMPTY_CART',
  'SET_TO_CART',
], 'CATALOGUE')

export const actions = {
  load: actionCreator(types.LOAD, 'customerCode', 'account', 'suggested'),
  loadSuccess: actionCreator(types.LOAD_SUCCESS, 'items', 'cart'),
  loadFail: actionCreator(types.LOAD_FAIL, 'data'),
  search: actionCreator(types.SEARCH, 'search', 'customerCode', 'account'),
  searchSuccess: actionCreator(types.SEARCH_SUCCESS, 'searchItems'),
  searchFail: actionCreator(types.SEARCH_FAIL),
  addToCart: actionCreator(types.ADD_TO_CART, 'item', 'qty', 'excededBoxes'),
  removeFromCart: actionCreator(types.REMOVE_FROM_CART, 'item'),
  emptyCart: actionCreator(types.EMPTY_CART),
  loadUserInfo: actionCreator(types.LOAD_USER_INFO, 'account', 'url', 'token', 'orderId', 'suggested'),
  loadUserInfoSuccess: actionCreator(types.LOAD_USER_INFO_SUCCESS, 'info'),
  loadUserInfoFail: actionCreator(types.LOAD_USER_INFO_FAIL, 'e'),
  sendCart: actionCreator(types.SEND_CART, 'url', 'token', 'orderId', 'order', 'userId', 'whatsappNumber', 'total'),
  sendCartSuccess: actionCreator(types.SEND_CART_SUCCESS),
  sendCartFail: actionCreator(types.SEND_CART_FAIL),
  setToCart: actionCreator(types.SET_TO_CART, 'item', 'qty'),
}

export default { actions, types }
