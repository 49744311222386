import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  SearchBarContainer, Search, IconSearch, IconDelete,
} from './CatalogueSearchBar.styled'

const CatalogueSearchBar = ({ searchAction, translations }) => {
  const placeholder = _.get(translations, 'searchbarPlaceholder', 'Search products')
  const [name, setName] = useState('')

  const handleSubmit = (event) => {
    event.preventDefault()
    setName('')
    searchAction('')
  }

  const onChangeSearch = (event) => {
    searchAction(event.target.value)
    setName(event.target.value)
  }

  return (
    <SearchBarContainer>
      <IconSearch className="icon-search" />
      <Search
        type="text"
        placeholder={placeholder}
        onChange={onChangeSearch}
        value={name}
      />
      {
        name.length > 0
        && <IconDelete className="icon-close" onClick={handleSubmit} />
      }
    </SearchBarContainer>
  )
}

CatalogueSearchBar.defaultProps = {
  translations: {},
}

CatalogueSearchBar.propTypes = {
  searchAction: PropTypes.func.isRequired,
  translations: PropTypes.object,
}

export default CatalogueSearchBar
