import React, { Suspense, lazy } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ItemList, SkeletonItem, FakeWrapper } from './CatalogueItemsList.styled'

const Item = lazy(() => import('../CatalogueItem/CatalogueItem'))

const CatalogueItemsList = ({
  items, cart: cartType, catalogue: { cart }, propRef,
}) => (
  <FakeWrapper>
    <ItemList cart={cartType} ref={propRef}>
      {
        items.map((item, i) => {
          const qty = cart[item.sku] ? cart[item.sku].qty : 0
          const excededBoxes = cart[item.sku] ? cart[item.sku].excededBoxes : false
          const newObj = Object.assign({ qty, excededBoxes }, item)
          const key = i + 1
          return (
            <Suspense
              key={key}
              fallback={
                <SkeletonItem cart={cartType} />
              }
            >
              <Item
                cart={cartType}
                item={newObj}
                key={newObj.sku}
                propRef={propRef}
              />
            </Suspense>
          )
        })
      }
    </ItemList>
  </FakeWrapper>
)

CatalogueItemsList.propTypes = {
  items: PropTypes.array.isRequired,
  cart: PropTypes.bool.isRequired,
  catalogue: PropTypes.object.isRequired,
  propRef: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  catalogue: state.catalogue,
})

export default connect(mapStateToProps, {})(CatalogueItemsList)
