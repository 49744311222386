import React from 'react'
import PropTypes from 'prop-types'
import {
  TabsWrapper, Tab,
} from './CatalogueNavBar.styled'

const CatalogueNavBar = ({
  items, selectItem, selected,
}) => (
  <TabsWrapper>
    {items.map((item) => {
      const { label, id } = item
      return (
        <Tab key={id}>
          <input
            type="radio"
            name="tabs"
            value={id}
            checked={id === selected}
            id={id}
            onChange={e => selectItem(e.target.value)}
          />
          <label htmlFor={id}>
            {label}
          </label>
        </Tab>
      )
    })}
  </TabsWrapper>
)

CatalogueNavBar.propTypes = {
  items: PropTypes.array.isRequired,
  selectItem: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
}

export default CatalogueNavBar
