import { combineReducers } from 'redux'

import config from './config'
import chart from './chart'
import catalogue from './catalogue'

export default combineReducers({
  catalogue,
  config,
  chart,
})
