import { takeEvery, call, put } from 'redux-saga/effects'
import axios from 'axios'
import { datadogRum } from '@datadog/browser-rum'
import chartActions from '../actions/chart'

const {
  LOADING,
} = chartActions.types

const { loaded, error, } = chartActions.actions

const fetchData = (url) => {
  return axios.get(url)
}

function* loadData({ payload: { url }}){
  try {
    const { data } = yield call(fetchData, url)
    yield put(loaded(data))
  } catch(e) {
    datadogRum.addError(e)
    yield put(error())
  }
}

export function* watchChartLoading() {
 yield takeEvery(LOADING, loadData)
}
