import styled, { css } from 'styled-components'

export const Button = styled.button`
  background-color: ${props => props.theme.whatsappButton};
  border: none;
  border-radius: 22px;
  color: ${props => props.theme.white};
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.2em;
  line-height: 44px;
  margin-top: 16px;
  outline: none;
  text-transform: uppercase;
  width: 100%;

  ${props => (props.isCart ? css`background-color: ${props.theme.main};` : null)}

  &:disabled {
    background-color: ${props => props.theme.disabledButton};
  }
`

export const Footer = styled.div`
  flex: 0 0 auto;
  display: block;
  padding: 10px 0;
  width: 100%;
  border-top: 1px solid ${props => props.theme.secondary};
  margin-top: 10px;
`

export const FiguresContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

export const Indicator = styled.div`
  display: flex;
  flex-direction: column;

  &:last-child {
    text-align: right;
  }
`

export const Name = styled.div`
  color: ${props => props.theme.colorText};
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const Alert = styled.div`
  display: block;
  margin-top: 16px;
  position: relative;
  text-align: center;
  width: 100%;

  > p {
    color: ${props => props.theme.warningText};
    font-size: 12px;
    line-height: 14px;
    margin: 0 30px;

    span {
      margin-right: 4px;
    }
  }
`
