import qs from 'qs'
import { types } from '../actions/catalogue'
import history from '../routes/history'

const { location: { search } } = history
const parsed = qs.parse(search, { ignoreQueryPrefix: true })
const { orderId } = parsed

const initialState = {
  items: [],
  isLoading: false,
  searchItems: [],
  cart: {},
  error: false,
  errorMessage: '',
  success: false,
  loades: false,
  statusCode: 0,
}

const catalogueReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case types.LOAD: {
      return { ...state, isLoading: true }
    }
    case types.LOAD_SUCCESS: {
      const { items, cart = {} } = payload

      const currentOrder = localStorage.getItem('currentOrder')
      const savedCart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : {}

      let newCart = {}
      if (Object.keys(cart).length) {
        newCart = cart
      } else if (currentOrder && currentOrder === orderId) {
        newCart = savedCart
      }

      localStorage.setItem('cart', JSON.stringify(newCart))
      localStorage.setItem('currentOrder', orderId)

      return {
        ...state, orderId, items, cart: newCart, isLoading: false, loaded: true,
      }
    }
    case types.LOAD_FAIL: {
      return {
        ...state,
        isLoading: false,
        info: {},
        error: true,
        errorMessage: 'Does not return information products price',
        statusCode: 500,
      }
    }
    case types.SEARCH: {
      const { search } = payload
      const { items } = state
      let searchItems = []
      if (search !== '') {
        searchItems = items.filter(
          item => item.name.toLowerCase().search(search.toLowerCase()) >= 0,
        )
      }
      return { ...state, isLoading: false, searchItems }
    }
    case types.SEARCH_SUCCESS: {
      const { searchItems } = payload
      return {
        ...state, searchItems, isLoading: false,
      }
    }
    case types.SEARCH_FAIL: {
      return { ...state, searchItems: [], isLoading: false }
    }
    case types.ADD_TO_CART: {
      const { item, qty, excededBoxes } = payload
      const { cart } = state
      if (cart[item.sku]) {
        cart[item.sku].qty += qty
        cart[item.sku].excededBoxes = cart[item.sku].qty > cart[item.sku].maximum_boxes
        if (cart[item.sku].qty === 0) delete cart[item.sku]
      } else if (qty > 0) {
        cart[item.sku] = Object.assign(item, { qty, excededBoxes })
      }

      localStorage.setItem('cart', JSON.stringify(cart))
      localStorage.setItem('currentOrder', orderId)

      return {
        ...state,
        cart,
      }
    }
    case types.SET_TO_CART: {
      const { item, qty } = payload
      console.log({
        item,
      })
      const { cart } = state
      if (cart[item.sku]) {
        cart[item.sku].qty = qty
        cart[item.sku].excededBoxes = cart[item.sku].qty > cart[item.sku].maximum_boxes
        if (cart[item.sku].qty === 0) delete cart[item.sku]
      } else if (qty > 0) {
        cart[item.sku] = Object.assign(item, { qty, excededBoxes: qty > item.excededBoxes })
      }

      localStorage.setItem('cart', JSON.stringify(cart))
      localStorage.setItem('currentOrder', orderId)

      return {
        ...state,
        cart,
      }
    }
    case types.REMOVE_FROM_CART: {
      const { item } = payload
      const { cart } = state
      delete cart[item.sku]

      if (Object.keys(cart).length) {
        localStorage.setItem('cart', JSON.stringify(cart))
      } else {
        localStorage.removeItem('cart')
      }

      return { ...state, cart }
    }
    case types.EMPTY_CART: {
      localStorage.removeItem('cart')
      return { ...state, cart: {} }
    }
    case types.LOAD_USER_INFO: {
      return { ...state, isLoading: true, info: {} }
    }
    case types.LOAD_USER_INFO_SUCCESS: {
      const { info } = payload
      return {
        ...state,
        isLoading: false,
        info,
      }
    }
    case types.LOAD_USER_INFO_FAIL: {
      const { e } = payload
      return {
        ...state,
        isLoading: false,
        info: {},
        error: true,
        errorMessage: e.error,
        statusCode: e.statusCode,
      }
    }
    case types.SEND_CART: {
      return { ...state, isLoading: true }
    }
    case types.SEND_CART_SUCCESS: {
      localStorage.removeItem('cart')
      return { ...state, isLoading: false, success: true }
    }
    case types.SEND_CART_FAIL: {
      return { ...state, isLoading: false, error: true }
    }
    default:
      return state
  }
}

export default catalogueReducer
