import React from 'react'
import PropTypes from 'prop-types'

const alertStyle = {
  backgroundColor: '#151515',
  color: 'white',
  padding: '10px',
  borderRadius: '3px',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  boxShadow: '0px 2px 2px 2px rgba(0, 0, 0, 0.03)',
  fontFamily: 'Arial',
  width: '300px',
  boxSizing: 'border-box'
}

const ToastAlert = ({ message, options, style, close }) => {
  return (
    <div style={{ ...alertStyle, ...style }}>
      <span style={{ flex: 2 }}>{message}</span>
    </div>
  )
}

ToastAlert.propTypes = {
  message: PropTypes.string,
  options: PropTypes.object,
  style: PropTypes.object,
  close: PropTypes.func,
}

export default ToastAlert
