import styled from 'styled-components'

export const SearchBarContainer = styled.div`
  background: #fff;
  box-shadow: 0px 4px 12px rgba(215, 215, 215, 0.5);
  border-radius: 22px;
  height: 44px;
  margin-bottom: 16px;
  margin-top: 10px;
  position: relative;
  flex: 0 0 auto;

  &:before,
  &:after {
    clear: both;
    content: "";
    display: block;
    height: 0;
    speak: none;
  }
`

export const Search = styled.input`
  border: none;
  border-radius: 22px;
  box-sizing: border-box;
  font-size: 13px;
  height: 100%;
  outline: none;
  padding-left: 44px;
  width: 100%;

  &::-webkit-input-placeholder {
    color: ${props => props.theme.gray};
  }
`

export const IconSearch = styled.i`
  color: ${props => props.theme.disabled};
  font-size: 15px;
  left: 18px;
  position: absolute;
  top: 10px;
`

export const IconDelete = styled.i`
  color: ${props => props.theme.disabled};
  font-size: 20px;
  position: absolute;
  right: 16px;
  top: 6px;
`
