import styled from 'styled-components'

export const Wrapper = styled.div`
  background: ${props => props.theme.main};
  bottom: 0;
  color: #fff;
  font-family: 'Heebo', sans-serif;
  font-style: normal;
  left: 0;
  padding: 16px;
  padding-top: 24px;
  position: absolute;
  right: 0;
  z-index: 9;
  /* display: none; */
`

export const Overlay = styled.div`
  background: rgba(14, 14, 14, 0.5);
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  z-index: 10;
  display: ${props => (props.isVisible ? 'block' : 'none')}
`

export const Header = styled.p`
  font-family: 'Heebo', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.2px;
  margin-bottom: 24px;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const Indicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  width: 100%;
`

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`

export const Message = styled.p`
  font-family: 'Heebo';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.2px;
  flex-grow: 1;
`

export const Footer = styled.div`
  display: flex;
  align-items: center;
`

export const ButtonConfirm = styled.button`
  background: #FFFFFF;
  border: none;
  border-radius: 22px;
  display: flex;
  color: ${props => props.theme.main};
  align-items: center;
  justify-content: center;
  line-height: 44px;
  font-family: Heebo;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  padding: 0 24px;
  margin-right: 4px;
  flex-grow: 1;
  cursor: pointer;
  @media screen and (max-width: 411px) {
    font-size: 11px;
    padding: 0 5px;
  }
`

export const ButtonCancel = styled.button`
  background: #FFFFFF;
  border: none;
  border-radius: 22px;
  display: flex;
  color: ${props => props.theme.main};
  align-items: center;
  justify-content: center;
  line-height: 44px;
  font-family: Heebo;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  padding: 0 24px;
  margin-left: 4px;
  cursor: pointer;
  @media screen and (max-width: 411px) {
    font-size: 11px;
    padding: 0 5px;
  }
`
