import chartActions from '../actions/chart'

const initialState = {
  data: [],
}

const { types } = chartActions

const chartReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch(type) {
    case types.LOADING: {
      return {...state, isLoading: true }
    }
    case types.LOADED: {
      const { data } = payload
      return {...state, data: data.data, isLoading: false } 
    }
    case types.ERROR: {
      return {...state, isLoading: false }
    }
    default:
      return state
  }
}

export default chartReducer
