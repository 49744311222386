import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Nehue Haas';
    src: url('../fonts/Nehue Haas/Linotype - Neue Haas Unica Pro.otf');
  }

  body {
    margin: 0;
    font-family: 'Nehue Haas';
  }

  html, body #root {
    height: 100%;
  }

  p {
    margin 0;
  }

`

export default GlobalStyle
