import React, { Component } from 'react'
import PropTypes from 'prop-types'
import onClickOutside from 'react-onclickoutside'
import {
  Container, Label, Header, Title, Wrapper, DropdownContainer, Icon,
} from './Dropdown.styled'
import DropdownList from './DropdownList'

class Dropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      listOpen: false,
      value: props.value,
      list: props.list,
      name: props.name,
      placeholder: props.placeholder,
      label: props.label,
      searchValue: '',
    }
  }

  getRowTitle = (item) => {
    if (item) {
      const { getTitle } = this.props
      return getTitle ? getTitle(item) : item
    }
    return null
  }

  filterItems = (e) => {
    const searchValue = e.target.value
    const { list } = this.state
    const { search } = this.props
    const filteredList = list.filter(item => search(item, searchValue))

    this.setState({ searchValue, filteredList })
  }

  toggleList = () => {
    const { disabled = false } = this.props
    if (!disabled) {
      const { listOpen } = this.state
      this.setState({ listOpen: !listOpen })
    }
  }

  selectItem = (item) => {
    const { recordSelection } = this.props
    const { name } = this.state
    recordSelection(name, item)
    this.setState({
      listOpen: false,
      // value: item,
    })
  }

  handleClickOutside= () => {
    this.setState({
      listOpen: false,
    })
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps) {
      const { value: stateValue, list: stateList } = this.state
      const { value: nextValue, list: nextList } = nextProps
      const isParentValueChanged = stateValue !== nextValue
      const isParentListChanged = stateList !== nextList

      if (isParentValueChanged) {
        this.setState({ value: nextValue })
      }

      if (isParentListChanged) {
        this.setState({ list: nextList })
      }
    }
  }

  render() {
    const {
      search, searchPlaceholder,
    } = this.props
    const {
      list, listOpen, value, label, required, placeholder, filteredList, searchValue,
    } = this.state

    const header = this.getRowTitle(value)
    const items = filteredList || list
    return (
      <Container>
        <Label htmlFor="id">
          {label}
          {required ? (
            <span style={{ color: 'red' }}>
              *
            </span>
          ) : ''}
        </Label>
        <DropdownContainer isOpen={listOpen}>
          <Wrapper>
            <Header role="listbox" onClick={() => this.toggleList()}>
              <Title>
                {header || placeholder}
              </Title>
              <Icon />
            </Header>
            {
              listOpen && (
                <DropdownList
                  searchValue={searchValue}
                  search={search}
                  filterItems={this.filterItems}
                  searchPlaceholder={searchPlaceholder}
                  selectItem={this.selectItem}
                  items={items}
                  getRowTitle={this.getRowTitle}
                  value={value}
                />
              )
            }
          </Wrapper>
        </DropdownContainer>
      </Container>
    )
  }
}

Dropdown.defaultProps = {
  disabled: false,
  getTitle: () => null,
  search: null,
  searchPlaceholder: '',
  recordSelection: () => null,
  list: [],
  name: '',
  placeholder: '',
  label: '',
}
Dropdown.propTypes = {
  list: PropTypes.array,
  disabled: PropTypes.bool,
  getTitle: PropTypes.func,
  recordSelection: PropTypes.func,
  search: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}


export default onClickOutside(Dropdown)
