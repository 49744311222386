import React from 'react'
import PropTypes from 'prop-types'
import {
  Wrapper, Header, Content, Indicator, Icon, Message,
  Footer, ButtonConfirm, ButtonCancel, Overlay,
} from './ModalAlert.styled'
import recycling from './recycling.svg'
import boxIcon from './box.svg'

const ModalAlert = ({
  itemsReturnables, onClose, showModal, itemsExceded,
  sendRequest,
}) => (
  <Overlay isVisible={showModal}>
    <Wrapper>
      <Header>
        <b>EVITE O NÃO RECEBIMENTO DE MERCADORIAS!</b>
        <br />
        Confirme os alertas abaixo antes de enviar o pedido
      </Header>
      <Content>
        {
          itemsReturnables > 0 && (
            <Indicator>
              <Icon>
                <img src={recycling} alt="recycling" />
              </Icon>
              <Message>
                Comprei,
                {' '}
                <b>
                  {itemsReturnables}
                  {' '}
                  itens retornáveis
                </b>
                {' '}
                e devolverei os
                <br />
                {' '}
                vasilhames na entrega
              </Message>
            </Indicator>
          )
        }
        {
          itemsExceded > 0 && (
            <Indicator>
              <Icon>
                <img src={boxIcon} alt="box" />
              </Icon>
              <Message>
                Estou de acordo com a compra de
                {' '}
                {itemsExceded}
                {' '}
                itens no total, e receberei essa quantidade
              </Message>
            </Indicator>
          )
        }
      </Content>
      <Footer>
        <ButtonConfirm
          onClick={() => {
            sendRequest()
            onClose()
          }}
        >
          estou de acordo
        </ButtonConfirm>
        <ButtonCancel onClick={() => onClose()}>
          Ajustar pedido
        </ButtonCancel>
      </Footer>
    </Wrapper>
  </Overlay>
)

ModalAlert.propTypes = {
  itemsReturnables: PropTypes.number.isRequired,
  itemsExceded: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  sendRequest: PropTypes.func.isRequired,
}

export default ModalAlert
