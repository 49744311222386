import {
  takeEvery, call, put, all,
} from 'redux-saga/effects'
import axios from 'axios'
import { datadogRum } from '@datadog/browser-rum'
import configActions from '../actions/config'
import { watchChartLoading } from './chart'
import API_CONFIG from '../api-config'
import {
  watchCatalogueLoad,
  watchCatalogueUserInfoLoad,
  watchCatalogueSendCart,
} from './catalogue'

const {
  LOADING,
} = configActions.types

const {
  loadedConfig,
  loadedErrorConfig,
} = configActions.actions

const { themes: { url: CONFIG_API } } = API_CONFIG

const fetchConfig = (domain, webview) => {
  const url = `${CONFIG_API}/domain/${domain}/webview/${webview}/theme`
  const token = process.env.REACT_APP_WEBVIEW_THEME_TOKEN

  return axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then(response => response)
}


function* loadConfig({ payload: { domain, webview } }) {
  try {
    const { data } = yield call(fetchConfig, domain, webview)
    yield put(loadedConfig({ data }))
  } catch (e) {
    datadogRum.addError(e)
    yield put(loadedErrorConfig())
  }
}


function* watchConfigLoading() {
  yield takeEvery(LOADING, loadConfig)
}


function* rootSaga() {
  yield all([
    watchConfigLoading(),
    watchChartLoading(),
    watchCatalogueLoad(),
    // watchCatalogueSearch(),
    watchCatalogueSendCart(),
    watchCatalogueUserInfoLoad(),
  ])
}

export default rootSaga
