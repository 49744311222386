import styled from 'styled-components'

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  height: 30px;
  flex: 0 0 auto;
`

export const Tab = styled.span`
  flex: 1 1 auto;

  label {
    color: ${props => props.theme.colorText};
    font-size: 13px;
    height: 100%;
    width: 100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-bottom: 2px solid ${props => props.theme.borderNavBar};
  }

  input[type="radio"]:checked & {
    border-bottom: 2px solid ${props => props.theme.main};
  }

  input[type="radio"] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;

    &:checked + label {
      border-bottom: 2px solid ${props => props.theme.main};
    }
  }
`
