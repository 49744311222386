import styled from 'styled-components'

export const ViewContainer = styled.div`
  height: 100%;
  
  @media (min-width: 425px) {
    height: 100%;
    min-width: 425px;
    max-width: 425px;
    min-height: 720px;
    max-height: 720px;
    overflow: auto;

    -webkit-box-shadow: 3px 9px 17px 1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 3px 9px 17px 1px rgba(0, 0, 0, 0.75);
    box-shadow: 3px 9px 17px 1px rgba(0, 0, 0, 0.75);
  }
`

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;

  @media (min-width: 425px) {
    justify-content: center;
    align-items: center;
  }
`
