import configActions from '../actions/config'

const initialState = {
  theme: {
    background: '#FDFDFD',
    disabled: '#CCC',
    enabled: '#000',
    active: '#FFF',
    secondary: '#5A55F2',
    background_active: '#F8F8F8',
    border: '#E5E5E5',
    main: '#2D2D3A',
  },
  config: {},
}

const { types } = configActions

const configReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case types.LOADING: {
      return { ...state, isLoading: true }
    }
    case types.LOADED: {
      const { theme, config = {} } = payload.data
      return {
        ...state, theme, config, isLoading: false,
      }
    }
    case types.LOADED_ERROR: {
      return { ...state, isLoading: false }
    }
    default:
      return state
  }
}

export default configReducer
