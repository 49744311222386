import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import qs from 'qs'
import { isAndroid } from 'react-device-detect'
import { withRouter } from 'react-router'
import Loader from 'react-loader-spinner'
import CatalogueSearchBar from '../../components/CatalogueSearchBar'
import CatalogueNavBar from '../../components/CatalogueNavBar/CatalogueNavBar'
import CatalogueItemList from '../../components/CatalogueItemsList/CatalogueItemsList'
import Dropdown from '../../components/Dropdown'
import {
  CatalogueWrapper, DropdownContainer, LoadingContainer, ErrorContainer,
  EmptyCartContainer, NoProductsContainer, HeaderImage,
} from './Catalogue.styled'
import CatalogueFooter from '../../components/CatalogueFooter'
// import ModalAlert from '../../components/ModalAlert/ModalAlert'
import { actions } from '../../actions/catalogue'
import env from '../../api-config'

const myRef = React.createRef()

class Catalogue extends Component {
  state = {
    category: 'all',
    selected: 'catalogue',
    loaded: false,
    showModal: false,
  }

  componentDidMount() {
    const {
      config,
      location: { search },
      match: { params },
    } = this.props
    const parsed = qs.parse(search, { ignoreQueryPrefix: true })
    const { orderId, suggested } = parsed
    const { account } = params
    const { purchaseOrder: { url, token } } = env

    if (suggested) {
      this.setState({ selected: 'cart' })
    }

    if (Object.keys(config).length) {
      const { loadUserInfo } = this.props
      if (orderId) {
        loadUserInfo({
          account, url, token, orderId, suggested,
        })
      }

      const { categories } = config
      this.setState({ category: categories[0] })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const isLoaded = _.get(nextProps, 'catalogue.loaded', false)
    if (!prevState.loaded && isLoaded) {
      // console.log({ nexProps, prevState })
      const cart = _.get(nextProps, 'catalogue.cart', [])
      return { ...prevState, loaded: true, selected: Object.keys(cart).length ? 'cart' : 'catalogue' }
    }

    return prevState
  }

  handleScroll = () => {
    if (myRef.current !== null) {
      myRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  selectCategory = (name, value) => {
    this.setState({ category: value })
    this.handleScroll()
  }

  search = (value) => {
    const {
      searchCatalogue,
      match: { params },
    } = this.props
    const { catalogue: { info: { customerCode } } } = this.props
    const { account } = params
    searchCatalogue({ search: value, customerCode, account })
  }

  selectNavItem = (val) => {
    this.setState({ selected: val })
  }

  openModal = () => {
    this.setState({ showModal: true })
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }

  sortByKey = (array, key) => array.sort((a, b) => {
    const x = a[key]
    const y = b[key]
    // eslint-disable-next-line no-nested-ternary
    return ((x < y) ? -1 : ((x > y) ? 1 : 0))
  })

  getUnique = (arr, comp) => {
    const unique = arr
      .map(e => e[comp])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => arr[e]).map(e => arr[e])
    return unique
  }

  render() {
    const {
      config: {
        translations, currency, loaderConfig, categories = [], minPrice,
      },
      catalogue: {
        items, cart, searchItems, isLoading, error, success, errorMessage, statusCode,
      },
      emptyCart,
      location: { search },
    } = this.props
    const parsed = qs.parse(search, { ignoreQueryPrefix: true })
    const { userId, orderId: orderIdURL } = parsed
    const { category, selected, showModal } = this.state
    // console.log(error)

    let itemList = []
    if (searchItems.length > 0) {
      itemList = searchItems
    } else if (selected === 'cart') {
      itemList = Object.values(cart)
    } else if (category) {
      itemList = category === 'all' ? items : items.filter(item => {
        if (item.hasOwnProperty('category2') && (item.category2.length > 0) && !(item.category === category)) {
          return item.category2.some(element => element === category)
        } else return item.category === category
      })
    }
    const cartLength = Object.keys(cart).length
    const totalItems = Object.keys(cart).reduce((acc, sku) => acc + cart[sku].qty, 0)
    const catalogueTitle = _.get(translations, 'navCatalogue', 'PRODUCTS')
    const noProducts = _.get(translations, 'noProducts', 'you not have added products to the cart')
    const dropdownPlaceholder = _.get(translations, 'dropdownPlaceholder', 'Select one')
    const dropdownAll = _.get(translations, 'dropdownAllItems', 'All')
    const cartTitle = _.get(translations, 'navCart', 'CART')
    const tipCatalogueItem = totalItems > 0 ? ` (${totalItems})` : ''
    const navItems = [
      { id: 'catalogue', label: catalogueTitle },
      { id: 'cart', label: `${cartTitle}${tipCatalogueItem}` },
    ]
    const textEmptyCart = _.get(translations, 'emptyCart', 'Empty cart')

    this.sortByKey(itemList, 'position')
    this.getUnique(itemList, 'sku')
    const filterdItemList = this.getUnique(itemList, 'sku')
    const newErrorMessage = statusCode === 404 ? 'Not found, document not exist with id' : errorMessage
    const itemsReturnables = Object.keys(cart).reduce((acc, key) => {
      const item = cart[key]
      let returnables = 0
      if (item.returnable) {
        returnables = item.number_units * item.qty
      }
      return acc + returnables
    }, 0)
    const itemsExceded = Object.keys(cart).reduce((acc, key) => {
      const item = cart[key]
      let exceded = 0
      // if (item.excededBoxes) {
        exceded = item.number_units * item.qty
      // }
      return acc + exceded
    }, 0)

    return (
      <CatalogueWrapper isAndroid={isAndroid}>
        { isLoading ? (
          <LoadingContainer>
            <Loader {...loaderConfig} />
          </LoadingContainer>
        ) : (
          <>
            {
              error ? (
                <ErrorContainer>
                  <p>{newErrorMessage}</p>
                </ErrorContainer>
              ) : (
                <>
                  <HeaderImage />
                  <CatalogueSearchBar
                    translations={translations}
                    searchAction={this.search}
                  />
                  {
                    !searchItems.length && (
                      <CatalogueNavBar
                        items={navItems}
                        selectItem={this.selectNavItem}
                        selected={selected}
                      />
                    )
                  }
                  {
                    selected === 'catalogue' && !searchItems.length && (
                      <DropdownContainer>
                        <Dropdown
                          placeholder={dropdownPlaceholder}
                          list={categories}
                          recordSelection={this.selectCategory}
                          value={category}
                          getTitle={item => (item === 'all' ? dropdownAll : item)}
                        />
                      </DropdownContainer>
                    )
                  }

                  {
                    itemList.length > 0 ? (
                      <CatalogueItemList
                        cart={selected === 'cart'}
                        items={filterdItemList}
                        propRef={myRef}
                      />
                    ) : (
                      <NoProductsContainer>
                        <p>{noProducts}</p>
                      </NoProductsContainer>
                    )
                  }

                  {
                    selected === 'cart' && (
                      <EmptyCartContainer>
                        { cartLength > 0
                          && (
                            <button type="button" onClick={() => emptyCart()}>
                              {textEmptyCart}
                            </button>
                          )
                        }
                      </EmptyCartContainer>
                    )
                  }
                  <CatalogueFooter
                    translations={translations}
                    success={success}
                    currency={currency}
                    isCart={selected === 'cart'}
                    selectItem={this.selectNavItem}
                    minPriceConfig={minPrice}
                    userId={userId}
                    orderIdURL={orderIdURL}
                    itemsReturnables={itemsReturnables}
                    itemsExceded={itemsExceded}
                    openModal={this.openModal}
                    showModal={showModal}
                    closeModal={this.closeModal}
                  />
                </>
              )
            }
          </>
        )}
      </CatalogueWrapper>

    )
  }
}

Catalogue.defaultProps = {
  translations: {},
}

Catalogue.propTypes = {
  translations: PropTypes.object,
  config: PropTypes.object.isRequired,
  emptyCart: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  loadUserInfo: PropTypes.func.isRequired,
  // loadCatalogue: PropTypes.func.isRequired,
  searchCatalogue: PropTypes.func.isRequired,
  catalogue: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  catalogue: state.catalogue,
})

const dispatch = {
  // loadCatalogue: actions.load,
  searchCatalogue: actions.search,
  loadUserInfo: actions.loadUserInfo,
  emptyCart: actions.emptyCart,
}

export default withRouter(connect(mapStateToProps, dispatch)(Catalogue))
