import axios from 'axios'
import qs from 'qs'
import config from '../api-config'
import history from '../routes/history'

const { location: { search } } = history
const parsed = qs.parse(search, { ignoreQueryPrefix: true })
const { orderId } = parsed
const { catalogue: { api, token } } = config

export const getData = (customerCode, account, suggested) => {
  const AuthToken = process.env.REACT_APP_SQUIDEX_TOKEN
  const query = suggested ? '?suggested=true' : ''
  const url = `${api}/${orderId}${query}`
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${AuthToken}`,
      'x-api-key': token,
    },
  })
}

export const searchData = (customerCode, account, searchValue) => new Promise(async (resolve, reject) => {
  try {
    resolve({})
  } catch (e) {
    reject({})
  }
})
