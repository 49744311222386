import { createTypes, actionCreator } from 'redux-action-creator'

const types = createTypes(['LOADING', 'LOADED', 'LOADED_ERROR'], 'CONFIG')

const actions = {
  loadingConfig: actionCreator(types.LOADING, 'domain', 'webview'),
  loadedConfig: actionCreator(types.LOADED, 'data'),
  loadedErrorConfig: actionCreator(types.LOADED_ERROR),
}


export default { types, actions }
