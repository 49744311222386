import styled from 'styled-components'
import search from './search.svg'

export const Container = styled.div`
  width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`

export const DropdownContainer = styled.div`
  background-color: #FFF;
  border: 0;
  box-sizing: border-box;
  border-radius: ${props => (props.isOpen ? '0' : '4px')};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  box-shadow: 2px 2px 6px rgba(224, 224, 224, 0.3);
  cursor: pointer;
  font-size: 14px;
  outline: 0;
  position: relative;
  width: 100%;

  /* &:hover {
    border: ${props => (props.isOpen ? '1px solid #000' : '1px solid #000')};
  } */

`

export const Label = styled.label`
  font-size: 11px;
  letter-spacing: 0.3em;
  text-transform: uppercase;

`

export const Header = styled.div`
  width: auto;
  height: 44px;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${props => props.theme.main};
  box-shadow: rgba(75, 75, 75, 0.25) 2px 2px 4px;
  border-radius: 4px;
`

export const Title = styled.div`
  color: ${props => props.theme.black};
  margin-left: 20px;
  line-height: 44px;
  font-size: 14px;
`

export const ContainerList = styled.div`
  background: #FFF;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0px 4px 12px rgba(215,215,215,0.5);
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  position: absolute;
  margin: 0;
  max-height: ${props => (props.visible ? '302px' : '0')};
  padding: 0;
  transition: max-height 200ms ease-in;
  width:100%;
  z-index: 100;
  border: 1px solid ${props => props.theme.main};
  border-top: 0;
  box-sizing: border-box;
  border-radius: 4px;
`

export const List = styled.ul`
  overflow-y: auto;
  list-style-type: none;
  padding: 0px;

  ::-webkit-scrollbar {
      width: 2px;
      height: 1em;
  }
  ::-webkit-scrollbar-button {
      background: #ccc;
  }
  ::-webkit-scrollbar-track-piece {
      background: #888;
  }
  ::-webkit-scrollbar-thumb {
      background: ${props => props.theme.main};
  }
`

export const Item = styled.li`
  cursor: pointer;
  background-color: ${props => (props.selected ? props.theme.bodyBg : '#FFF')};
  color: ${props => props.theme.black};
  font-size: 14px;
  height: 44px;
  line-height: 44px;
  padding-left: 19px;

  &:hover,
  &:active{
    background-color: ${props => props.theme.bodyBg};
  }

  .flag-icon {
    width: 20px;
    height: 20px;
    display: inline-block
  }
`

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

export const SearchItem = styled.div`
  text-align: center;

`

export const Input = styled.input`
  background-color: #FEFEFE;
  border: none;
  border-radius: 22px;
  width: 206px;
  height: 40px;
  margin: 17px 0 15px 0;
  background-image: url(${search});
  background-repeat: no-repeat;
  background-position: calc(1% + 1rem) calc(1% + .8rem);
  padding-left: 36px;
  box-sizing: border-box;
  font-size: 14px;
  color: #000;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #FEFEFE;
  }
`

export const Icon = styled.div`
  background-color: transparent;
  outline: 0;
  border: none;
  position: relative;
  padding: 0.5rem;
  margin-right: 1rem;
  margin-top: .5rem;

  ::before {
    content: '';
    border-right: 9px solid transparent;
    border-left: 9px solid transparent;
    border-top: 9px solid ${props => props.theme.main};
    clear: both;
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left:0
  }
`
