import React, { Component } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Loader from 'react-loader-spinner'
import ConfigActions from '../../actions/config'
import { ViewContainer, Container } from './ThemeComponent.styled'


const {
  loadingConfig,
} = ConfigActions.actions


const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 100vh;
  justify-content: center;
`


class ThemeComponent extends Component {
  componentDidMount() {
    const { account } = this.props.match.params
    this.props.loadConfig(account, this.props.webview)
  }

  render() {
    const childProp = React.cloneElement(this.props.children, { config: this.props.config.config })

    return (
      this.props.config.isLoading
        ? (
          <LoaderContainer>
            <Loader
              type="Grid"
              color="#5A55F2"
              height="100"
              width="100"
            />
          </LoaderContainer>
        )
        : (
          <ThemeProvider theme={this.props.config.theme}>
            <Container>
              <ViewContainer>
                {childProp}
              </ViewContainer>
            </Container>
          </ThemeProvider>
        )
    )
  }
}

const mapDispatchToProps = dispatch => ({
  loadConfig: (domain, webview) => {
    dispatch(loadingConfig({ domain, webview }))
  },
})


export default withRouter(connect(state => state, mapDispatchToProps)(ThemeComponent))
