import styled, { css } from 'styled-components'

export const CatalogueWrapper = styled.div`
  background: #F9FAFB;
  padding: 0 18px 0 18px;
  height: 100%;
  max-height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;

  ${props => (props.isAndroid
    ? css`
      height: calc(100vh - 30px);
      max-height: calc(100vh - 30px);
      min-height: calc(100vh - 30px);
    ` : null)
}

  @media (min-width: 425px) {
    max-width: 425px;
    min-height: 720px;
    max-height: 720px;
  }
`

export const TabContent = styled.div`
  position: 'relative'
`

export const HeaderImage = styled.div`
  background-image: url(${props => props.theme.headerImage});
  flex:0;
  min-height: ${props => (props.theme.headerImage ? '3rem' : '0')};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 0.5rem;
`

export const ShowCartButtonContainer = styled.div`
  background: ${props => props.theme.white};
  bottom: 0;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.5);
  display: block;
  left: 0;
  padding: 12px 16px;
  position: fixed;
  right: 0;
`

export const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;

  > p {
    color: ${props => props.theme.colorText};
    font-size: 18px;
    line-height: 20px;
    opacity: 0.5;
    text-align: center;
  }
`

export const DropdownContainer = styled.div`
  flex: 0 0 auto;
`

export const LoadingContainer = styled.div`
  margin: auto;
`

export const EmptyCartContainer = styled.div`
  flex: 0 0 auto;
  margin: 10px 0;
  position: relative;
  text-align: center;
  width: 100%;

  > button {
    background: none;
    border: none;
    color: ${props => props.theme.darkGray};
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    max-width: 220px;
    opacity: 0.6;
    position: relative;
    text-align: center;
    text-decoration: underline;
    width: 100%;
    outline: none;
  }
`

export const NoProductsContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  height: 100%;
  width: 100%;

  > p {
    color: ${props => props.theme.colorText};
    font-size: 18px;
    line-height: 20px;
    opacity: 0.5;
  }
`
