// eslint-disable-next-line import/no-mutable-exports
let API_CONFIG = {} // eslint

const hostname = window && window.location && window.location.hostname

if (/staging2/.test(hostname)) {
  // staging 2 config
  API_CONFIG = {
    themes: {
      url: 'https://api-global.yalochat.com/themes-api',
    },
    catalogue: {
      api: 'https://ccfo9uurmk.execute-api.us-east-1.amazonaws.com/production/br/client-order',
      token: 'mO6mFOPhZc7bHrr2KPPkM43fUbMnAEkC6UzCAutJ',
    },
    purchaseOrder: {
      url: 'https://ccfo9uurmk.execute-api.us-east-1.amazonaws.com/production/br/purchase-order',
      token: 'mO6mFOPhZc7bHrr2KPPkM43fUbMnAEkC6UzCAutJ',
    },
    whatsAppNumber: '5215549061793',
  }
} else if (/staging/.test(hostname)) {
  // staging config
  API_CONFIG = {
    themes: {
      url: 'https://api-global.yalochat.com/themes-api',
    },
    catalogue: {
      api: 'https://ccfo9uurmk.execute-api.us-east-1.amazonaws.com/production/br/client-order',
      token: 'mO6mFOPhZc7bHrr2KPPkM43fUbMnAEkC6UzCAutJ',
    },
    purchaseOrder: {
      url: 'https://ccfo9uurmk.execute-api.us-east-1.amazonaws.com/production/br/purchase-order',
      token: 'mO6mFOPhZc7bHrr2KPPkM43fUbMnAEkC6UzCAutJ',
    },
    whatsAppNumber: '5215549061793',
  }
} else if (/localhost/.test(hostname)) {
  // local config
  API_CONFIG = {
    themes: {
      url: 'https://api-global.yalochat.com/themes-api',
    },
    catalogue: {
      api: 'https://ccfo9uurmk.execute-api.us-east-1.amazonaws.com/production/br/client-order',
      token: 'mO6mFOPhZc7bHrr2KPPkM43fUbMnAEkC6UzCAutJ',
    },
    purchaseOrder: {
      url: 'https://ccfo9uurmk.execute-api.us-east-1.amazonaws.com/production/br/purchase-order',
      token: 'mO6mFOPhZc7bHrr2KPPkM43fUbMnAEkC6UzCAutJ',
    },
    whatsAppNumber: '5215549061793',
  }
} else {
  // prod config
  API_CONFIG = {
    themes: {
      url: 'https://api-global.yalochat.com/themes-api',
    },
    catalogue: {
      api: 'https://ccfo9uurmk.execute-api.us-east-1.amazonaws.com/production/br/client-order',
      token: 'mO6mFOPhZc7bHrr2KPPkM43fUbMnAEkC6UzCAutJ',
    },
    purchaseOrder: {
      url: 'https://ccfo9uurmk.execute-api.us-east-1.amazonaws.com/production/br/purchase-order',
      token: 'mO6mFOPhZc7bHrr2KPPkM43fUbMnAEkC6UzCAutJ',
    },
    whatsAppNumber: '5511976212763',
  }
}

export default API_CONFIG
