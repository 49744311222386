import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Catalogue from '../containers/Catalogue'
import ThemeComponent from '../containers/ThemeComponent'

const catalogue = (
  <ThemeComponent webview="catalogue">
    <Catalogue />
  </ThemeComponent>
)

export default () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/:account/catalogue" render={() => catalogue} />
    </Switch>
  </BrowserRouter>
)
