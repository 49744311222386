import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  List, Item, Input, SearchItem, ContainerList,
} from './Dropdown.styled'

class DropdownList extends Component {
  state = { didMount: false }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ didMount: true })
    }, 0)
    try {
      if (this.nameInput) {
        this.nameInput.focus()
      }
    } catch (e) {}
  }

  render() {
    const { didMount } = this.state
    const {
      searchValue, search, filterItems, searchPlaceholder, selectItem, items, getRowTitle, value,
    } = this.props
    return (
      <ContainerList visible={didMount}>
        {
          search
            ? (
              <SearchItem>
                <Input
                  type="text"
                  value={searchValue}
                  onChange={filterItems}
                  placeholder={searchPlaceholder}
                  ref={(input) => { this.nameInput = input }}
                />
              </SearchItem>
            ) : null
        }
        <List>
          {items.map((item, index) => (
            <Item
              onClick={() => selectItem(item)}
              key={item.id || index}
              selected={JSON.stringify(item) === JSON.stringify(value)}
            >
              { item.url
                && <img src={item.url} alt={getRowTitle(item)} />
              }
              {getRowTitle(item)}
            </Item>
          ))}
        </List>
      </ContainerList>
    )
  }
}
DropdownList.defaultProps = {
  items: [],
  selectItem: () => null,
  searchValue: '',
  search: null,
  filterItems: () => null,
  searchPlaceholder: '',
}

DropdownList.propTypes = {
  items: PropTypes.array,
  searchValue: PropTypes.string,
  search: PropTypes.func,
  filterItems: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  selectItem: PropTypes.func,
  getRowTitle: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
}

export default DropdownList
