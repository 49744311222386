import { createTypes, actionCreator } from 'redux-action-creator'

const types = createTypes(['LOADING', 'LOADED', 'ERROR'], 'CHART')

const actions = {
  loading: actionCreator(types.LOADING, 'url'),
  loaded: actionCreator(types.LOADED, 'data'),
  error: actionCreator(types.ERROR),
}

export default { types, actions }
