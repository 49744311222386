import {
  takeLatest, call, put, debounce,
} from 'redux-saga/effects'
import axios from 'axios'
import { datadogRum } from '@datadog/browser-rum'
import { actions, types } from '../actions/catalogue'
import { getData, searchData } from '../repository/squidex'

const getUserInfo = (url, token, orderId) => axios({
  method: 'GET',
  url: `${url}/${orderId}`,
  headers: {
    'x-api-key': token,
  },
}).then(({ data: response }) => {
  const { result, success } = response
  if (success && result) {
    if (result.closed) {
      return Promise.reject(new Error('Closed'))
    }
    return (result)
  }
  return Promise.reject((response))
})

const updateCart = (url, token, orderId, order, whatsappNumber, total) => axios({
  method: 'PUT',
  url: `${url}/${orderId}`,
  headers: {
    'x-api-key': token,
  },
  data: {
    orderId,
    order,
    total,
    us001: true,
  },
}).then(({ data: response }) => {
  const { success } = response
  if (success) {
    window.location = `https://wa.me/${whatsappNumber}`
    return (response)
  }
  return Promise.reject(new Error('not Success'))
}).catch((error) => {
  console.log(error)
  datadogRum.addError(error)
  window.location = `https://wa.me/${whatsappNumber}`
})

export function* loadCatalogue({ payload: { customerCode, account, suggested } }) {
  try {
    const { data } = yield call(getData, customerCode, account, suggested)
    const { data: { items, cart } } = data
    yield put(actions.loadSuccess({ items, cart }))
  } catch (e) {
    console.log(e)
    datadogRum.addError(e)
    yield put(actions.loadFail({ e }))
  }
}

export function* searchCatalogue({ payload: { search, customerCode, account } }) {
  try {
    if (search === '') { yield put(actions.searchSuccess({ searchItems: [] })) } else {
      const { searchItems } = yield call(searchData, customerCode, account, search)
      yield put(actions.searchSuccess({ searchItems }))
    }
  } catch (e) {
    datadogRum.addError(e)
    yield put(actions.searchFail())
  }
}

export function* loadUserInfo({
  payload: {
    account, url, token, orderId, suggested,
  },
}) {
  try {
    const info = yield call(getUserInfo, url, token, orderId)
    const { customerCode } = info
    yield put(actions.loadUserInfoSuccess({ info }))
    yield put(actions.load({ customerCode, account, suggested }))
  } catch (e) {
    datadogRum.addError(e)
    yield put(actions.loadUserInfoFail({ e }))
  }
}

export function* sendCart({
  payload: {
    url, token, orderId, order, whatsappNumber, total,
  },
}) {
  try {
    const orderArray = Object.values(order)
    yield call(updateCart, url, token, orderId, orderArray, whatsappNumber, total)
    yield put(actions.sendCartSuccess())
  } catch (e) {
    datadogRum.addError(e)
    yield put(actions.sendCartFail())
  }
}

export function* watchCatalogueLoad() {
  yield takeLatest(types.LOAD, loadCatalogue)
}

export function* watchCatalogueSearch() {
  yield debounce(1000, types.SEARCH, searchCatalogue)
}

export function* watchCatalogueUserInfoLoad() {
  yield takeLatest(types.LOAD_USER_INFO, loadUserInfo)
}

export function* watchCatalogueSendCart() {
  yield takeLatest(types.SEND_CART, sendCart)
}
