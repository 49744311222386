import React from 'react'
import { Provider } from 'react-redux'
import { Provider as AlertProvider } from 'react-alert'
import ToastAlert from './lib/ToastAlert'
import store from './store'
import Routes from './routes'
import GlobalStyle from './global.styled'
import { Container } from './App.styled'
import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_APP_ID,
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  site: 'us5.datadoghq.com',
  service: process.env.REACT_APP_DATADOG_SERVICE_NAME,
  env: process.env.REACT_APP_DATADOG_ENV,
  sampleRate: 100,
  replaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
})

const options = {
  position: 'bottom center',
  timeout: 900,
  offset: '30px',
  transition: 'scale',
}

datadogRum.startSessionReplayRecording()
const App = () => (
  <Provider store={store}>
    <GlobalStyle />
    <AlertProvider template={ToastAlert} {...options}>
      <Container>
        <Routes />
      </Container>
    </AlertProvider>
  </Provider>
)

export default App
