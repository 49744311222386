/* eslint-disable no-template-curly-in-string */
import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Mustache from 'mustache'
import {
  Footer, FiguresContainer, Indicator, Name, Button, Alert,
} from './CatalogueFooter.styled'
import ModalAlert from '../ModalAlert/ModalAlert'
import { actions } from '../../actions/catalogue'
import env from '../../api-config'
import API_CONFIG from '../../api-config'

const { whatsAppNumber } = API_CONFIG

const format = require('number-format.js')

const CatalogueFooter = ({
  translations, catalogue, sendCart, config: { config },
  success, currency, isCart, selectItem, closeModal, showModal,
  orderIdURL, itemsExceded, itemsReturnables, openModal,
}) => {
  const { cart, info = {} } = catalogue
  const { minPrice } = info
  const cartLength = Object.keys(cart).length
  const totalItems = Object.keys(cart).reduce((acc, sku) => acc + cart[sku].qty, 0)
  const minRequest = _.get(translations, 'minRequest', 'La cantidad mínima por pedido es de $5,000 MXN.')
  const totalText = _.get(translations, 'total', 'Total')
  const textSendProducts = success ? _.get(translations, 'returnToWhatsapp', 'Return to WhatsApp')
    : _.get(translations, 'sendRequest', 'Send Products')
  const goToCart = _.get(translations, 'goToCart', 'CART')
  const total = Object.keys(cart).reduce((acc, key) => {
    const item = cart[key]
    const itemTotal = item.qty * item.price
    return acc + itemTotal
  }, 0)

  const tempLeftRequest = (minPrice - total).toFixed(2)
  const btnValue = isCart ? 'cart' : 'catalogue'
  const isCartAvailable = cartLength
  const getTotal = value => (Math.round((value * 1000) / 10) / 100).toFixed(2)
  const totalFixed = getTotal(total)
  const newTotal = format('#.##0,00', totalFixed, { enforceMaskSign: true })
  const leftRequest = format('#.##0,00', tempLeftRequest, { enforceMaskSign: true })
  const isInactive = total < minPrice
  const sendRequest = () => {
    const { purchaseOrder: { url, token } } = env
    sendCart({
      orderId: orderIdURL,
      order: cart,
      url,
      token,
      whatsappNumber: whatsAppNumber,
      total: totalFixed.toString(),
    })
  }

  const goToCartFunc = () => {
    selectItem('cart')
  }

  const action = () => {
    if (itemsExceded > 0 || itemsReturnables > 0) {
      return openModal()
    }
    return sendRequest()
  }

  const backToWhatsapp = () => {
    window.location = `http://wa.me/${whatsAppNumber}`
  }


  return (
    <Fragment>
      <Footer>
        <FiguresContainer>
          <Indicator>
            <Name>
              {`${totalText} ${totalItems} ${totalItems === 1 ? 'caixa' : 'caixas'}`}
            </Name>
          </Indicator>
          <Indicator>
            <Name>
              {currency}
              {' '}
              {newTotal}
            </Name>
          </Indicator>
        </FiguresContainer>
        {
            isInactive
            && (
            <Alert>
              <p>
                {Mustache.render(minRequest, { leftRequest })}
              </p>
            </Alert>
            )
          }
        {
            !isCart && (
              <Button
                disabled={!isCartAvailable}
                isCart
                onClick={() => goToCartFunc()}
              >
                {goToCart}
                {' '}
                { totalItems > 0 && (
                  <span>
                    {'('}
                    {totalItems}
                    {')'}
                  </span>
                )
                }
              </Button>
            )
          }

        {
          isCart && (success
            ? (
              <Button
                onClick={() => backToWhatsapp()}
                value={btnValue}
              >
                {textSendProducts}
              </Button>
            )
            : (
              <Button
                disabled={isInactive}
                success={success}
                onClick={() => action()}
                value={btnValue}
                id="sendRequestButton"
              >
                {textSendProducts}
              </Button>
            )
          )
        }
      </Footer>
      {
        isCart && (
          <ModalAlert
            itemsReturnables={itemsReturnables}
            itemsExceded={itemsExceded}
            showModal={showModal}
            onClose={closeModal}
            onOpen={openModal}
            sendRequest={sendRequest}
          />
        )
      }
    </Fragment>
  )
}

CatalogueFooter.defaultProps = {
  translations: {},
  currency: '$',
  orderIdURL: undefined,
}

CatalogueFooter.propTypes = {
  translations: PropTypes.object,
  currency: PropTypes.string,
  catalogue: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  sendCart: PropTypes.func.isRequired,
  success: PropTypes.bool.isRequired,
  isCart: PropTypes.bool.isRequired,
  selectItem: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  itemsReturnables: PropTypes.number.isRequired,
  itemsExceded: PropTypes.number.isRequired,
  orderIdURL: PropTypes.string,
}

const mapStateToProps = state => ({
  catalogue: state.catalogue,
  config: state.config,
})

const dispatch = {
  sendCart: actions.sendCart,
}

export default withRouter(connect(mapStateToProps, dispatch)(CatalogueFooter))
